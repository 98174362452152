import React from 'react';
import SEO from '../components/seo/Seo';
import PrivacyPolicyHeader from "../components/privacy-policy/PrivacyPolicyHeader";
import Header from "../components/headers/Header";
import PrivacyPolicyContent from "../components/privacy-policy/PrivacyPolicyContent";
import Footer from "../components/layouts/Footer";

function PolitikaVarstvaOsebnihPodatkov(props) {
    return (
        <div>
            <SEO title="Politika varstva osebnih podatkov" description="Namen politike varstva osebnih podatkov je seznaniti posameznike, uporabnike storitev, sodelavce in zaposlene ter druge osebe (v nadaljevanju: posameznik), ki sodelujejo z zavodom Javni zavod za kulturo, mladino in šport Litija (v nadaljevanju: organizacija) o namenih in pravnih podlagah, varnostnih ukrepih ter pravicah posameznikov glede obdelave osebnih podatkov, ki jih izvaja naša organizacija."/>
            <Header/>
            <PrivacyPolicyHeader/>
            <PrivacyPolicyContent/>
            <Footer />
        </div>
    );
}

export default PolitikaVarstvaOsebnihPodatkov;