import React from 'react';

function PrivacyPolicyContent(props) {
    return (
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner-wrapper">
                            <div className="inner privacy-content">

                                <p>
                                    Namen politike varstva osebnih podatkov je seznaniti posameznike, uporabnike storitev, sodelavce in zaposlene ter druge osebe (v nadaljevanju: posameznik), ki sodelujejo z zavodom Javni zavod za kulturo, mladino in šport Litija (v nadaljevanju: organizacija) o namenih in pravnih podlagah, varnostnih ukrepih ter pravicah posameznikov glede obdelave osebnih podatkov, ki jih izvaja naša organizacija.
                                </p>

                                <p>
                                    <strong>Cenimo vašo zasebnost, zato vaše podatke vedno skrbno varujemo.</strong>
                                </p>

                                <p>
                                    Vaše osebne podatke obdelujemo v skladu z evropsko zakonodajo (Uredba (EU) 2016/697 o varstvu posameznikov pri obdelavi osebnih podatkov in o pretoku takih podatkov (v nadaljevanju: Splošna uredba)) in veljavno zakonodajo s področja varstva osebnih podatkov (Zakon o varstvu osebnih podatkov (ZVOP-1, Ur. l. RS, št. 94/07)) in drugo zakonodajo, ki nam daje pravno podlago za obdelavo osebnih podatkov.
                                </p>

                                <p>
                                    Politika varstva osebnih podatkov vsebuje informacije za posameznike, na kak način naša organizacija kot upravljavec obdeluje osebne podatke, ki jih prejme od posameznika na osnovi pravnih podlag, ki jih opisujemo v nadaljevanju.
                                </p>

                                <h2>Upravljavec</h2>

                                <p>
                                    Upravljalec osebnih podatkov je organizacija:
                                    Javni zavod za kulturo, mladino in šport Litija,
                                    Trg na Stavbah 8a, 1270 Litija,
                                    01 890 02 00,
                                    info@mclitija.si.
                                </p>

                                <h2>Pooblaščena oseba</h2>

                                <p>
                                    Pooblaščena oseba V skladu z določilom 37. člena Splošne uredbe smo za pooblaščeno osebo za varstvo podatkov imenovali družbo:
                                    DATAINFO.SI, d.o.o.,
                                    Tržaška cesta 85, SI-2000 Maribor,
                                    www.datainfo.si,
                                    e-pošta: dpo@datainfo.si,
                                    telefon: +386 (0) 2 620 4 300.
                                </p>

                                <h2>Osebni podatki</h2>

                                <p>
                                    Osebni podatek pomeni katero koli informacijo v zvezi z določenim ali določljivim posameznikom (v nadaljnjem besedilu: posameznik, na katerega se nanašajo osebni podatki); določljiv posameznik je tisti, ki ga je mogoče neposredno ali posredno določiti, zlasti z navedbo identifikatorja, kot je ime, identifikacijska številka, podatki o lokaciji, spletni identifikator, ali z navedbo enega ali več dejavnikov, ki so značilni za fizično, fiziološko, genetsko, duševno, gospodarsko, kulturno ali družbeno identiteto tega posameznika.
                                </p>

                                <h2>Nameni obdelave in podlage za obdelavo podatkov</h2>

                                <p>Organizacija zbira in obdeluje vaše osebne podatke na naslednjih pravnih podlagah:
                                    <ul>
                                        <li>obdelava je potrebna za izpolnitev zakonske obveznosti, ki velja za upravljavca;</li>
                                        <li>obdelava je potrebna za opravljanje naloge v javnem interesu ali pri izvajanju javne oblasti,
                                            dodeljene upravljavcu;</li>
                                        <li>obdelava je potrebna za izvajanje pogodbe, katere pogodbena stranka je posameznik, na
                                            katerega se nanašajo osebni podatki, ali za izvajanje ukrepov na zahtevo takega posameznika
                                            pred sklenitvijo pogodbe;</li>
                                        <li>obdelava je potrebna zaradi zakonitih interesov za katere si prizadeva upravljavec ali tretja
                                            oseba;</li>
                                        <li>
                                            posameznik, na katerega se nanašajo osebni podatki, je privolil v obdelavo njegovih osebnih
                                            podatkov v enega ali več določenih namenov;
                                        </li>
                                        <li>
                                            obdelava je potrebna za zaščito življenjskih interesov posameznika, na katerega se nanašajo
                                            osebni podatki, ali druge fizične osebe.
                                        </li>
                                    </ul>
                                </p>

                                <h2>
                                    Izpolnitev zakonske obveznosti oziroma opravljanje naloge v javnem interesu
                                </h2>

                                <p>
                                    Na osnovi določil v zakonu organizacija obdeluje predvsem podatke o svojih zaposlenih, kar ji omogoča delovna zakonodaja. Tako organizacija na podlagi zakonske obveznosti obdeluje predvsem naslednje vrste osebnih podatkov: ime in priimek, spol, datum rojstva, EMŠO, davčna številka, kraj, občino in državo rojstva, državljanstvo, prebivališče za potrebe izvajanja pogodbe o zaposlitvi in obveznosti iz tega naslova.
                                </p>

                                <p>Pravna podlaga za obdelavo osebnih podatkov posameznikov pa je tudi: Zakon o sistemu plač v javnem sektorju, Zakon o javnih uslužbencih, Zakon o varstvu pred naravnimi in drugimi nesrečami… V omejenih primerih je v organizaciji dopustna obdelava osebnih podatkov tudi na osnovi javnega interesa.</p>

                                <h2>
                                    Izvajanje pogodbe
                                </h2>

                                <p>
                                    V primeru, ko z organizacijo sklenete določeno pogodbo, ta predstavlja pravno podlago za obdelavo osebnih podatkov. Vaše osebne podatke smemo tako obdelovati za sklenitev in izvajanje pogodbe, kot je npr. prodaja vstopnic, članstvo v društvu, izvedba izobraževanj, pogodba o izvajanju storitev ipd. Če posameznik osebnih podatkov ne posreduje, organizacija ne more skleniti pogodbe, prav tako vam organizacija ne more izvesti storitve oziroma dostaviti blaga ali drugih produktov v skladu s sklenjeno pogodbo, saj nima potrebnih podatkov za izvedbo. Organizacija lahko na podlagi opravljanja zakonite dejavnosti posameznike in uporabnike njenih storitev na njihov elektronski naslov obvešča o svojih o storitvah, dogodkih, izobraževanjih, ponudbah in drugih vsebinah. Posameznik lahko kadarkoli zahteva prekinitev tovrstnega komuniciranja in obdelave osebnih podatkov ter prekliče prejemanje sporočil preko povezave za odjavo v prejetem sporočilu, ali kot zahtevek po elektronski pošti na info@mclitija.si ali z redno pošto na naslov organizacije.
                                </p>


                                <h2>
                                    Zakoniti interes
                                </h2>

                                <p>
                                    Uveljavljanje pravne podlage zakonitega interesa je sicer omejena za obdelavo s strani javnih organov pri opravljanju njihovih nalog. Vseeno pa lahko organizacija osebne podatke obdeluje tudi na podlagi zakonitega interesa, za katerega si organizacija prizadeva v omejenem obsegu. Slednje ni dopustno, kadar nad takimi interesi prevladajo interesi ali temeljne pravice in svoboščine posameznika, na katerega se nanašajo osebni podatki, ki zahtevajo varstvo osebnih podatkov. V primeru uporabe zakonitega interesa organizacija vedno opravi presojo skladno s Splošno uredbo.
                                </p>

                                <p>
                                    Tako lahko posameznike občasno obveščamo o storitvah, dogodkih, izobraževanjih, ponudbah in drugih vsebinah preko elektronske pošte, preko telefonskih klicev in po navadni pošti. Posameznik lahko kadarkoli zahteva prekinitev tovrstnega komuniciranja in obdelave osebnih podatkov in prekliče prejemanje sporočil preko povezave za odjavo v prejetem sporočilu, ali kot zahtevek po elektronski pošti na info@mclitija.si ali z redno pošto na naslov organizacije.
                                </p>

                                <h2>
                                    Obdelava na podlagi privolitve
                                </h2>

                                <p>
                                    V kolikor organizacija nima pravne podlage izkazane na osnovi zakona, opravljanja javne naloge, pogodbene obveznosti ali zakonitega interesa, sme posameznika zaprositi za privolitev. Tako lahko obdeluje določene osebne podatke posameznika tudi za naslednje namene, kadar posameznik poda za to soglasje:

                                    <ul>
                                        <li>naslov prebivališča in naslov elektronske pošte za namene obveščanja in komunikacije,</li>
                                        <li>davčna številka oziroma EMŠO za namene morebitne izvršbe v primeru neizpolnitve
                                            obveznosti (npr. neplačilo računa),</li>
                                        <li>fotografije, video posnetki in druge vsebine, ki se nanašajo na posameznika (npr. posnetki na
                                            javnih prireditvah in dogodkih) za namene dokumentiranja aktivnosti in obveščanja javnosti o
                                            delu in dogodkih v organizaciji;druge namene, za katere se posameznik strinja s privolitvijo.</li>
                                    </ul>
                                </p>

                                <p>
                                    Če posameznik poda soglasje za obdelavo osebnih podatkov in v nekem trenutku tega več ne želi, lahko zahteva prekinitev obdelave osebnih podatkov z zahtevkom po elektronski pošti na info@mclitija.si ali z redno pošto na naslov organizacije.
                                </p>

                                <h2>
                                    Obdelava je potrebna za zaščito življenjskih interesov posameznika
                                </h2>

                                <p>
                                    Organizacija lahko obdeluje osebne podatke posameznika, na katerega se nanašajo osebni podatki, v kolikor je to nujno za zaščito njegovih življenjskih interesov. Tako lahko organizacija poišče osebni dokument posameznika, preveri ali ta oseba obstaja v njegovi zbirki podatkov, preuči njegovo anamnezo ali naveže stik z njegovimi svojci za kar organizacija ne potrebujete njegove privolitve. Navedeno velja v primeru, ko je to nujno potrebno za zaščito življenjskih interesov posameznika.
                                </p>

                                <h2>
                                    Hramba in izbris osebnih podatkov
                                </h2>

                                <p>
                                    Organizacija bo hranila osebne podatke le toliko časa, dokler bo to potrebno za uresničitev namena, zaradi katerega so bili osebni podatki zbrani in obdelovani. V kolikor organizacija podatke obdeluje na podlagi zakona, jih bo organizacija hranila za obdobje, ki ga predpisuje zakon. Pri tem se nekateri podatki hranijo za časa sodelovanja z organizacijo, nekatere podatke pa je treba hraniti trajno. Osebne podatke, ki jih organizacija obdeluje na osnovi pogodbenega odnosa s posameznikom, organizacija hrani za obdobje, ki je potrebno za izvršitev pogodbe in še 6 let po njenem prenehanju, razen v primerih, ko pride med posameznikom in organizacijo do spora v zvezi s pogodbo. V takem primeru hrani organizacija podatke še 5 let po pravnomočnosti sodne odločbe, arbitraže ali sodne poravnave ali, če sodnega spora ni bilo, 5 let od dneva mirne razrešitve spora.
                                </p>

                                <p>
                                    Tiste osebne podatke, ki jih organizacija obdeluje na podlagi osebne privolite posameznika ali zakonitega interesa, bo organizacija hranila do preklica privolitve ali do zahteve do izbrisa podatkov. Po prejemu preklica ali zahteve za izbris se podatki izbrišejo najkasneje v 15 dneh. Organizacija lahko te podatke izbriše tudi pred preklicem, kadar je bil dosežen namen obdelave osebnih podatkov ali če tako določa zakon.
                                </p>

                                <p>
                                    Izjemoma lahko organizacija zavrne zahtevo za izbris iz razlogov iz Splošne uredbe, kot so našteti: uresničevanje pravice do svobode izražanja in obveščanja, izpolnjevanje pravne obveznosti obdelave, razlogi javnega interesa na področju javnega zdravja, nameni arhiviranja v javnem interesu, znanstveno- ali zgodovinskoraziskovalne nameni ali statistični nameni, izvajanje ali obramba pravnih zahtevkov.
                                </p>

                                <p>
                                    Po preteku obdobja hrambe upravljavec osebne podatke učinkovito in trajno izbriše ali anonimizira, tako da jih ni več mogoče povezati z določenim posameznikom
                                </p>

                                <h2>
                                    Pogodbena obdelava osebnih podatkov in iznos podatkov
                                </h2>

                                <p>
                                    Organizacija lahko za posamezne obdelave osebnih podatkov na osnovi pogodbe o pogodbeni obdelavi zaupa pogodbenemu obdelovalcu. Pogodbeni obdelovalci lahko zaupane podatke obdelujejo izključno v imenu upravljavca, v mejah njegovega pooblastila, ki je zapisan v pisni pogodbi oziroma drugem pravnem aktu in skladno z nameni, ki so opredeljeni v tej politiki zasebnosti.
                                </p>

                                <p>
                                    Pogodbeni obdelovalci, s katerimi sodeluje ponudnik so predvsem:
                                    <ul>
                                        <li>računovodske storitve in drugi ponudniki pravnega ter poslovnega svetovanja;</li>
                                        <li>vzdrževalci infrastrukture;</li>
                                        <li>vzdrževalci informacijskih sistemov;</li>
                                    </ul>
                                </p>

                                <p>
                                    Organizacija v nobenem primeru ne bo posredovala osebnih podatkov posameznika tretjim nepooblaščenim osebam.
                                </p>

                                <p>
                                    Pogodbeni obdelovalci smejo osebne podatke obdelovati zgolj v okviru navodil organizacije in osebnih podatkov ne smejo uporabiti za katerekoli druge namene.
                                </p>

                                <p>
                                    Organizacija kot upravljavec in njeni zaposleni osebnih podatkov ne iznašajo v tretje države (izven držav članic Evropskega gospodarskega prostora – članice EU ter Islandija, Norveška in Liechtenstein) in v mednarodne organizacije, razen v ZDA, pri so čemer razmerja s pogodbenimi obdelovalci iz ZDA urejena na podlagi standardnih pogodbenih klavzul (tipske pogodbe, ki jih je sprejela Evropska komisija) in/ali zavezujočih poslovnih pravil (ki jih sprejme organizacija in potrdijo nadzorni organi v EU)."
                                </p>

                                <h3>
                                    Varovanje podatkov in točnost podatkov
                                </h3>

                                <p>
                                    Organizacija skrbi za informacijsko varnost in varnost infrastrukture (prostorov in aplikativno sistemske programske opreme). Naši informacijski sistemi so med drugim zaščiteni s protivirusnimi programi in požarnim zidom. Uvedli smo ustrezne organizacijsko tehnične varnostne ukrepe, namenjene varstvu vaših osebnih podatkov pred naključnim ali nezakonitim uničenjem, izgubo, spreminjanjem, nepooblaščenim razkrivanjem ali dostopom ter pred drugimi nezakonitimi in nepooblaščenimi oblikami obdelave. V primeru posredovanja posebnih vrst osebnih podatkov, jih posredujemo v šifrirani obliki in zaščitene z geslom.
                                </p>

                                <p>
                                    Sami ste odgovorni, da nam svoje osebne podatke posredujete varno in da so posredovani podatki točni in verodostojni. Trudili se bomo, da bodo vaši osebni podatki, ki jih obdelujemo, točni in po potrebi ažurirani, občasno se lahko na vas obrnemo za potrditev točnosti vaših osebnih podatkov.
                                </p>

                                <h3>
                                    Pravice posameznika glede obdelave podatkov
                                </h3>
                                
                                <p>
                                    V skladu s Splošno uredbo ima posameznik sledeče pravice iz varstva osebnih podatkov:

                                    <ul>
                                        <li>Zahtevate lahko informacije o tem, ali imamo osebne podatke o vas in, če je tako, katere
                                            podatke imamo ter na kakšni podlagi jih imamo in zakaj jih uporabljamo.
                                        </li>
                                        <li>Zahtevate dostop do svojih osebnih podatkov, kar vam omogoča, da prejmete kopijo osebnih
                                            podatkov, ki jih imamo o vas ter preverite, ali jih obdelujemo zakonito.</li>
                                        <li>Zahtevate popravke osebnih podatkov, kot je popravek nepopolnih ali netočnih osebnih
                                            podatkov.</li>
                                        <li>Zahtevate izbris vaših osebnih podatkov, kadar ni razloga za nadaljnjo obdelavo oziroma kadar
                                            uveljavljate svojo pravico do ugovora glede nadaljnje obdelave.
                                        </li>
                                        <li>Ugovarjate nadaljnji obdelavi osebnih podatkov, kjer se zanašamo na zakoniti poslovni interes
                                            (tudi v primeru zakonitega interesa tretje osebe), kadar obstajajo razlogi, povezani z vašim
                                            posebnim položajem; ne glede na določilo prejšnjega stavka imate pravico kadarkoli
                                            ugovarjati, če obdelujemo vaše osebne podatke za namene neposrednega trženja.</li>
                                        <li>Zahtevate omejitev obdelave vaših osebnih podatkov, kar pomeni prekinitev obdelave osebnih
                                            podatkov o vas, na primer, če želite, da ugotovimo njihovo točnost ali preverimo razloge za
                                            njihovo nadaljnjo obdelavo.</li>
                                        <li>Zahtevate prenos vaših osebnih podatkov v strukturirani elektronski obliki k drugemu
                                            upravljavcu, v kolikor je to mogoče in izvedljivo.
                                        </li>
                                        <li>Prekličete privolitev oziroma soglasje, ki ste ga podali za zbiranje, obdelavo in prenos vaših
                                            osebnih podatkov za določen namen; po prejemu obvestila, da ste umaknili svojo privolitev, bomo prenehali obdelovati vaše osebne podatke za namene, ki ste jih prvotno sprejeli, razen če nimamo druge zakonite pravne podlage za to, da to storimo zakonito.</li>
                                    </ul>
                                </p>

                                <p>
                                    Če želite uveljavljati katero koli od prej navedenih pravic, pošljite zahtevek po elektronski pošti na info@mclitija.si ali z redno pošto na naslov organizacije.
                                </p>

                                <p>
                                    Dostop do vaših osebnih podatkov in uveljavljene pravic je za vas brezplačno. Vendar pa lahko zaračunamo razumno plačilo, v kolikor je zahteva posameznika, na katerega se nanašajo osebni podatki, očitno neutemeljena ali pretirana, zlasti če se ponavlja. V takšnem primeru lahko zahtevo tudi zavrnemo.
                                </p>

                                <p>
                                    V primeru uveljavljanja pravic iz tega naslova bomo morda morali od vas zahtevati določene informacije, ki nam bodo pomagale pri potrditvi vaše identitete, kar je le varnostni ukrep, ki zagotavlja, da se osebni podatki ne razkrijejo nepooblaščenim osebam.
                                </p>

                                <p>
                                    Pri uveljavljanju pravic iz tega naslova lahko uporabite tudi obrazec Informacijskega pooblaščenca, ki je dosegljiv na njihovi spletni strani.
                                </p>
                                <p>
                                    Povezava na: <a href="https://www.iprs.si/fileadmin/user_upload/doc/obrazci/ZVOP/Zahteva_za_seznanitev_z_lastnimi_osebnimi_podatki__Obrazec_SLOP_.doc" target="_blank">Zahteva za seznanitev z lastnimi osebnimi podatki (Obrazec SLOP)</a>
                                </p>

                                <p>
                                    V primeru, da menite, da so vaše pravice kršene, se lahko za zaščito ali pomoč obrnete na nadzorni organ oz. na informacijskega pooblaščenca. Povezava na: <a href="https://www.ip-rs.si/zakonodaja/reformaevropskega-zakonodajnega-okvira-za-varstvo-osebnih-podatkov/kljucna-podrocja-uredbe/prijavakrsitev/" target="_blank">Prijava kršitev</a>
                                </p>

                                <p>
                                    Če imate kakršnakoli vprašanja v zvezi z obdelavo vaših osebnih podatkov, se lahko vedno obrnete na
                                    nas.
                                </p>

                                <h2>
                                    Objava sprememb
                                </h2>

                                <p>Vsaka sprememba naše politike o varstvu osebnih podatkov bo objavljena na naši spletni strani. Z uporabo spletne strani posameznik potrjuje, da sprejema in soglaša s celotno vsebino te politike varstva osebnih podatkov.</p>

                                <p>Politiko varstva osebnih podatkov je sprejela odgovorna oseba organizacije dne, 12. 11. 2020</p>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyContent;